import {
  AccountBalanceOutlined,
  AssignmentIndOutlined,
  CorporateFareOutlined,
  DashboardOutlined,
  LocalOfferOutlined,
  NotificationsNoneOutlined,
  PaymentsOutlined,
  PeopleAltOutlined,
  PercentOutlined,
  SettingsOutlined,
  TaskOutlined,
} from '@mui/icons-material';
import { Logo, bore, hide } from '@/assets/images';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APP_VERSION } from '@/utils/constants';
import type { FC } from 'react';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import { cls } from '@/utils/funcs';
import { useTranslation } from 'react-i18next';

interface SidebarProps {}

const Sidebar: FC<SidebarProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const navItems = [
    {
      type: 'item',
      name: t('Business management'),
      icon: <CorporateFareOutlined />,
      href: '/dashboard/companies',
    },
    {
      type: 'item',
      name: t('Ref VAT Rates'),
      icon: <PercentOutlined />,
      href: '/dashboard/transactions',
    },
    {
      type: 'item',
      name: t('Admin management'),
      icon: <PeopleAltOutlined />,
      href: '/dashboard/admin-management',
    },
    {
      type: 'item',
      name: t('List of authorized currencies'),
      icon: <PaymentsOutlined />,
      href: '/dashboard/currencies',
    },
    {
      type: 'item',
      name: t('Bank list'),
      icon: <AccountBalanceOutlined />,
      href: '/dashboard/banks',
    },
    {
      type: 'item',
      name: t('Plans'),
      icon: <LocalOfferOutlined />,
      href: '/dashboard/plans',
    },
    {
      type: 'item',
      name: t('Accountants'),
      icon: <AssignmentIndOutlined />,
      href: '/dashboard/accountants',
    },
    {
      type: 'item',
      name: t('Receipts'),
      icon: <DashboardOutlined />,
      href: '/dashboard/receipts',
    },
    {
      type: 'item',
      name: t('Declarations'),
      icon: <TaskOutlined />,
      href: '/dashboard/declarations',
    },
    {
      type: 'item',
      name: t('REFERRAL_SETTINGS'),
      icon: <SettingsOutlined />,
      href: '/dashboard/referral-settings',
    },
    {
      type: 'item',
      name: t('Support'),
      icon: <SmsFailedIcon />,
      href: '/dashboard/support',
    },
    { type: 'divider' },
    {
      type: 'item',
      name: t('Notifications'),
      icon: <NotificationsNoneOutlined />,
      href: '/dashboard/notifications',
    },
    {
      type: 'item',
      name: t('Settings'),
      icon: <SettingsOutlined />,
      href: '/settings',
    },
  ];
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [selectedItem, setSelectedItem] = useState('companies');
  const navigate = useNavigate();

  const toggleSidebarExpansion = () =>
    setIsSidebarExpanded((isSidebarExpanded) => !isSidebarExpanded);

  const handleItemClick = (href: string) => {
    navigate(href);
  };

  useEffect(() => {
    setSelectedItem(location.pathname);
  }, [location.pathname]);

  return (
    <div>
      <div
        className={cls(
          'hidden min-[430px]:flex min-[430px]:flex-col min-[430px]:relative',
          isSidebarExpanded ? 'min-w-[250px]' : 'min-w-[65px]'
        )}
      >
        <div className="h-[100%] fixed overflow-y-auto scroll-bar-style overflow-x-hidden bg-first pt-[80px]">
          <div
            className={cls(
              'h-full flex duration-500 flex-col transition-width',
              isSidebarExpanded ? 'w-[280px]' : 'w-[65px]'
            )}
          >
            <div className="grow items-center justify-center flex bg-red">
              <div className="ml-auto mr-auto w-[80%] h-full pt-[3vh] pb-[3vh] bg flex flex-col gap-2">
                {navItems.map(({ type, name, icon, href }, idx) =>
                  type === 'item' ? (
                    <div
                      key={idx}
                      className={cls(
                        href === selectedItem && 'bg-blue-low-opacity',
                        isSidebarExpanded ? 'gap-1 pr-2' : 'gap-4 pl-[3px]',
                        'flex rounded-lg items-center py-3.5 cursor-pointer hover:bg-[#eef1fc] dark:hover:bg-[#1b1d2c] transition-colors duration-200'
                      )}
                      onClick={() => href && handleItemClick(href)}
                    >
                      <div
                        className={cls(
                          'pr-2 pl-3',
                          href === selectedItem
                            ? 'stroke-[#3B71FE]'
                            : 'stroke-[#8D98AF]'
                        )}
                      >
                        {icon}
                      </div>
                      <div className="flex justify-between w-full gap-2 items-center whitespace-nowrap">
                        <p
                          className={cls(
                            href === selectedItem
                              ? 'color-blue'
                              : 'color-black opacity-60',
                            'font-semibold w-full'
                          )}
                        >
                          {name}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <hr key={idx} className="my-2" />
                  )
                )}
              </div>
            </div>

            <div className="h-[450px] transition-all duration-300	w-[90%] ml-auto mr-auto justify-end items-center flex flex-col">
              <div className="flex justify-center pt-6 pb-6">
                <button onClick={toggleSidebarExpansion}>
                  <img
                    src={hide}
                    alt="Hide"
                    style={{ rotate: isSidebarExpanded ? undefined : '180deg' }}
                  />
                </button>
              </div>
              <div className="flex justify-center">
                {isSidebarExpanded ? (
                  <Logo />
                ) : (
                  <img alt="Bore-logo" src={bore} />
                )}
              </div>
              <h4 className="text-center pt-4 text-black pb-4">
                V ${APP_VERSION}
              </h4>
            </div>
          </div>
        </div>
      </div>

      {/* 430px (mobile) Navbar */}
      <div className="min-[430px]:hidden max-[430px]:block bg-first fixed bottom-0 w-full">
        <div className="flex  justify-between p-2">
          {navItems.map(({ name, icon }, idx) => (
            <div
              key={idx}
              className={`${
                name === selectedItem && 'bg-blue-low-opacity'
              } flex rounded-lg	 items-center pb-3 pr-4 pt-3 gap-4`}
            >
              <div
                className={`pr-2 pl-3 ${
                  name === selectedItem
                    ? 'stroke-[#3B71FE]'
                    : 'stroke-[#989A9E]'
                }`}
              >
                {icon}{' '}
              </div>
              <p
                className={`${
                  name === selectedItem
                    ? 'color-blue'
                    : 'color-black opacity-60'
                }   font-semibold w-full`}
              >
                {name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
