import type { TranslationsType } from '..';

const FR_TRANSLATIONS: TranslationsType = {
  // ==============================
  // App - Auth - SignIn
  // ==============================
  // 'Sign In': 'Se connecter',
  // Email: 'Email',
  // Password: 'Mot de passe',
  // 'Sign In': 'Se connecter',
  // 'Sign in with Gmail': 'Connectez-vous avec Gmail',
  // "Don't have an account yet?": "Vous n'avez pas encore un compte ?",
  // Validate: 'Valider',
  "Welcome back to login. As an admin, you have access to manage our user's information.":
    "Bienvenue à nouveau pour vous connecter. En tant qu'administrateur, vous avez accès à la gestion des informations de nos utilisateurs.",
  // 'Please enter a valid email address.':
  //   'Veuillez entrer une adresse e-mail valide.',
  // 'Password must be between 8 and 32 characters.':
  //   'Le mot de passe doit comporter entre 8 et 32 caractères.',
  // ==============================

  // ==============================
  // App - Wizard
  // ==============================
  'Company Information': 'Informations société',
  'Bank Accounts': 'Comptes bancaires',
  Subscription: 'Abonnement',
  // ==============================

  // ==============================
  // App - Dashboard
  // ==============================
  'Find your company': 'Chercher votre entreprise',
  Light: 'Clair',
  Dark: 'Sombre',
  Performances: 'Performances',
  Receipts: 'Justificatifs',
  Transactions: 'Transactions',
  Statements: 'Relevés',
  Documents: 'Documents',
  Notifications: 'Notifications',
  Settings: 'Paramètres',
  'Drag & drop': 'Glisser-déposer',
  'PNG, JPG, PDF (max. 900 MB)': 'PNG, JPG, PDF (max. 900 MB)',
  'Upload a file': 'Uploader un fichier',
  "Can't upload statement": 'Impossible de télécharger le relevé',
  'Statement does not exist': "le relevé n'existe pas",
  Search: 'Recherche',
  Confirm: 'Confirmer',
  update: 'mise à jour',
  'are you sure you want to delete this bank?':
    'Êtes-vous sûr de vouloir supprimer {{name}}?',
  Email: 'Email',
  // ==============================

  // ==============================
  // App - Dashboard - Home
  // ==============================
  'Hello, world!': 'Bonjour, le monde!',
  // ==============================

  // ==============================
  // App - Dashboard - Companies
  // ==============================
  'Company List': 'Gestion des entreprises',
  'Sorted by': 'Triée par',
  'No more companies': 'Plus de résultats',
  'No companies found': 'Aucune entreprise trouvée',
  'Error loading companies': 'Erreur de chargement des entreprises',
  Name: 'Nom',
  name: 'nom',
  'Tax Id': 'Tax Id',
  Address: 'Adresse',
  address: 'adresse',
  'Legal Status': 'Statut légal',
  Date: 'Date',
  date: 'date',
  logo: 'logo',

  // ==============================
  // ==============================
  // App - Settings
  // ==============================
  'Settings (02)': 'Paramétrages',
  Profile: 'Profil',
  Enterprise: 'Entreprise',
  // 'Team': 'Team',
  // 'Subscription': 'Subscription',

     // ==============================
  // App - Declarations
  // ==============================
  'Declarations templates': 'templates de déclaration',
  'Add declaration template': 'Ajouter une template de déclaration',
  'Update declaration template': 'Actualiser la template de déclaration',
  'title': 'Titre',
  'due date': 'Date d\'écheance',
  'is payable': 'Payable',
  'No templates found!':'Aucune template trouvé !',
  Declarations: 'Déclarations',
  'year': 'année',

  // ==============================
  // App - Referral Settings
  // ==============================
  REFERRAL_SETTINGS : 'Paramètres de parrainage',
  REFERRAL_CONFIG_UPDATED : 'Configuration de parrainage mise à jour',
  REFERRAL_CONFIG_CREATED : 'Configuration de parrainage créée',
  MAX_NUMBER_OF_REFERRALS : 'Nombre maximum de parrainages',
  DISCOUNT_PERCENTAGE : 'Pourcentage de réduction',
  ADDED_DAYS : 'Jours ajoutés',
  UPDATE : 'Mettre à jour',
  CREATE : 'Créer',


  // ==============================

  // ==============================
  // App - Other - Global Error Handler
  // ==============================
  'Server error. Please try again later.':
    'Erreur au niveau du serveur. Veuillez réessayer plus tard.',
  'Service Unavailable. Please try again later.':
    'Service non disponible. Veuillez réessayer plus tard.',
  Unauthorized: 'Non autorisé',
  "You don't have permissions to access this resource.":
    "Vous n'êtes pas autorisé à accéder à cette ressource.",
  // ==============================

  // ==============================
  // App - Other - Error Boundary
  // ==============================
  'Unexpected Error Occurred': "Une erreur inattendue s'est produite",
  // ==============================

  // ==============================
  // App - Other - Not Found
  // ==============================
  'Page Not Found': 'Page non trouvée',
  // ==============================

  // App - Other - Currencies
  // ==============================
  'Authorized currencies': 'Devises autorisés',
  'Created at': 'Créé à',
  Actions: 'Actions',
  'Add a new currency': 'Ajouter une devise',
  'Origin country': "Pays d'origine",
  'Currencies deleted successfully': 'Devises supprimées avec succès',
  'Add currency': 'Ajouter une devise',
  'Update currency': 'Mettre à jour la devise',
  'Currency full name': 'Nom complet de la devise',
  Symbol: 'Symbole',
  'Currencies created successfully': 'Devises créées avec succès',
  code: 'Code',
  decimal: 'Décimal',
  'Code already exists': 'Le code existe déjà',
  'There are no currencies': "Il n'y a pas de devises",
  'you can add a new currency by clicking the button above':
    'vous pouvez ajouter une nouvelle devise en cliquant sur le bouton ci-dessus',
  'Delete currency': 'Supprimer la devise',
  "You're about to delete this currency, are you sure?":
    'Vous êtes sur le point de supprimer cette devise, êtes-vous sûr?',

  // ==============================

  // App - Other - SideBar
  // ==============================
  'Business management': 'Gestion entreprises',
  'Ref categories': 'Ref des catégories',
  'Ref VAT Rates': 'Ref des Taux de TVA',
  'Admin management': 'Gestion admins',
  'List of authorized currencies': 'Liste devises autorisés',
  'Bank list': 'Liste des banques',
  'Bank name': 'Nom de banque',
  // ==============================

  // App - Other - Bank
  // ==============================
  'There are no bank': "Il n'y a pas de banque",
  'you can add a new bank by clicking the button above':
    'vous pouvez ajouter une nouvelle banque en cliquant sur le bouton ci-dessus',
  'Bank deleted successfully': 'Banque supprimée avec succès',
  'Update bank': 'Mettre à jour la banque',
  'Add bank': 'Ajouter une banque',
  'Delete Bank': 'Supprimer la banque',
  'Bank Logo': 'Logo de la Banque',
  'Add a new bank': 'Ajouter une nouvelle banque',
  // ==============================

  // App - Other - Plans
  // ==============================
  Plans: 'Plans',
  'Add a new plan': 'Ajouter un nouveau plan',
  Status: 'Statut',
  Price: 'Prix',
  Order: 'Commande',
  Displayable: 'Affichable',
  'There are no plans': "Il n'y a pas de plans",
  'you can add a new plan by clicking the button above':
    'vous pouvez ajouter un nouveau plan en cliquant sur le bouton ci-dessus',
  'Display this plan': 'Afficher ce plan',
  'Update plan': 'Plan de mise à jour',
  'Delete plan': 'Supprimer le forfait',
  'Add new feature': 'Ajouter une nouvelle fonctionnalité',
  'Please add a status.': 'Veuillez ajouter un statut.',
  'Please add a price.': 'Veuillez ajouter un prix.',
  'Please add an order.': 'Veuillez ajouter une commande.',
  Feature: 'Fonctionnalité',
  'Please add a feature.': 'Veuillez ajouter une fonctionnalité.',
  "You're about to delete this plan, are you sure?":
    'Vous êtes sur le point de supprimer ce plan, êtes-vous sûr?',
  'Add plan': 'Ajouter un plan',
  // ==============================

  // App - Other - Errors handlers
  // ==============================
  BANKS_BANK_ALREADY_EXISTS: 'La banque existe déjà',
  BANKS_UNSUPPORTED_FILE_TYPE:
    "Le type de fichier téléchargé n'est pas pris en charge",
  'Please add a name': 'Veuillez ajouter un nom',
  'Please add a code': 'Veuillez ajouter un code',
  'Your logo is not valid': "Votre logo n'est pas valide",
  BANKS_BANK_DOES_NOT_EXIST: "La banque n'existe pas",
  AUTH_BAD_CREDENTIALS: 'Email ou mot de passe incorrect.',
  AUTH_INVALID_RECAPTCHA_TOKEN: 'Recaptcha invalide',
  'Something went wrong, please try again later':
    "Quelque chose c'est mal passé. Merci d'essayer plus tard",
  ADMIN_USER_EXISTS: "L'utilisateur existe déjà",
  ADMIN_ROLE_NONEXISTENT: "Le rôle n'existe pas",
  ADMIN_SELF_DELETE_ERROR: 'Impossible de se supprimer',
  'The password must be at least 8 characters long,':
    'Le mot de passe doit comporter au moins 8 caractères, et inclure au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
  'Oops! Page not found': 'Oups!\n Page non trouvée',
  "This page doesn't exist or get removed. We suggest you back to home":
    "Cette page n'existe pas ou a été supprimée. Nous vous suggérons de retourner à l'accueil.",
  'Oh no!': 'Oh non!',
  'So sorry, something not working well please try again later or feel free to contact support.':
    "Désolé, quelque chose ne fonctionne pas bien. Veuillez réessayer plus tard ou n'hésitez pas à contacter le service d'assistance.",
  'Something went wrong': "Quelque chose n'a pas fonctionné",
  'Go back home': "Retour à l'accueil",
  // ==============================

  // App - Other - Admins
  // ==============================
  'Administrator management': 'Gestion des administrateurs',
  'Add an admin': 'Ajouter un admin',
  'There is no admin': "Il n'y a pas d'administrateur",
  'you can add a new admin by clicking the button above':
    'vous pouvez ajouter un nouvel administrateur en cliquant sur le bouton ci-dessus',
    "Supplier":"Fournisseur",
    "totalAmount": 'Montant TTC',
    "totalTaxAmount": 'TVA ',
    "netAmount":"Montant HT",
    "currency":"Devise",
    "supplierName":"Fournisseur",
    "clientName":"Client",
    "invoiceDate":"Date",
    "INVOICE_DATE_REQUIRED":"La date de la facture est un champ obligatoire",
    'Invoice Type': 'Type de facture',
    'Save changes': 'Enregistrer les modifications',
    "RECEIPT_VERIFICATION_UPDATE":"Êtes-vous sûr d'enregistrer ce justificatif ?",
    "SAVE_ADMIN_DESCRIPTION":"Vous êtes sur le point d'enregistrer ce justificatif avec les informations ci-dessous, veuillez confirmer votre action.",
    'No data table': "Aucun justificatif n'a été trouvé",
    'No data found in your search on this page':
    "Aucune donnée n'a été trouvée dans votre recherche sur cette page",
    'Go to home page': "Aller à la page d'accueil",
    'My receipt': 'Mes justificatifs',
  // ==============================
};

export default FR_TRANSLATIONS;
