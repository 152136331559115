import { type FC, useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { Input } from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { appToast } from '@/lib/ToastContainers';
import { SettingsService } from '@/services';

export type ReferralConfig = {
  maxNumber: number;
  discountPercent: number;
  addedDays: number;
};

export const ReferralSettings: FC = () => {
  const { t } = useAppTranslation();
  const [referralConfig, setReferralConfig] = useState<ReferralConfig | null>(
    null
  );
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty, isValid },
  } = useForm<ReferralConfig>();

  useEffect(() => {
    SettingsService.getReferralConfig().then((res) => {
      if (res.data) {
        setReferralConfig(res.data[0]);
        setValue('maxNumber', res.data[0].maxNumber);
        setValue('discountPercent', res.data[0].discountPercent);
        setValue('addedDays', res.data[0].addedDays);
      }
    });
  }, [reset]);


  const onSubmit = async (data: ReferralConfig) => {
    if (referralConfig) {
      SettingsService.updateReferralConfig(data)
        .then((res) => {
          setReferralConfig(res.data);
          appToast.success(t('REFERRAL_CONFIG_UPDATED'));
        })
        .catch(() => {
          appToast.error(t('Server error. Please try again later.'));
        });
    } else {
      SettingsService.createReferralConfig(data)
        .then((res) => {
          setReferralConfig(res.data);
          appToast.success(t('REFERRAL_CONFIG_CREATED'));
        })
        .catch(() => {
          appToast.error(t('Server error. Please try again later.'));
        });
    }
  };

  return (
    <Box className="mt-10 ml-10 pl-4 p-8 flex flex-col gap-2 border-1 border-gray-200 rounded  w-full items-center bg-first">
      {/* HEADER */}
      <Box className="flex justify-between items-center h-16 mt-4 mb-6">
        <Typography className="color-black" variant="h5" fontWeight="bold">
          {t('REFERRAL_SETTINGS')}
        </Typography>
      </Box>
      {/* BODY */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 w-1/2 color-black"
      >
        <Input
          className="bg-sec color-black w-full"
          label={t('MAX_NUMBER_OF_REFERRALS')}
          type="number"
          min="0"
          refs={register('maxNumber')}
          required
          defaultValue={referralConfig?.maxNumber || ''}
        />
        <Input
          className="bg-sec color-black w-full"
          label={t('DISCOUNT_PERCENTAGE')}
          type="number"
          step="0.01"
          max="100"
          min="0"
          refs={register('discountPercent')}
          defaultValue={referralConfig?.discountPercent || ''}
          required
        />
        <Input
          className="bg-sec color-black w-full"
          label={t('ADDED_DAYS')}
          type="number"
          min="0"
          refs={register('addedDays')}
          required
          defaultValue={referralConfig?.addedDays || ''}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isDirty || !isValid}
        >
          {referralConfig ? t('UPDATE') : t('CREATE')}
        </Button>
      </form>
    </Box>
  );
};
